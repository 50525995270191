import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import './home.css';
import { Image } from 'components/atoms';
import Seo from '../../seo/index';

const Home = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const useStyles = makeStyles(theme => ({
    root: {
      height: '100vh',
      width: '100vw',
      background: 'rgb(25,25,25)',
      position: 'relative'
    },
    centerPage: {
      height: '100vh',
      position: 'relative'
      // margin: 'auto',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center'
      // animation:
      //   'moveuptop 12s forwards'
      // marginTop: '50px',
      // marginBottom: '50px'
    },
    topPage: {
      position: 'relative',
      transform: 'translateY(-60%)',
      display: 'flex',
      // top: '50%',
      right: isMd ? '0%' : '10%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      animation:
        'moveuptop 5s forwards'
      // marginTop: '30px'
      // marginTop: '50px',
      // marginBottom: '50px'
    },
    typeWriterEffect: {
      textAlign: 'center',
      letterSpacing: '.07em',
      fontSize: isMd ? '100px' : '50px',
      fontWeight: '800',
      color: 'white',
      margin: '0 auto',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      // animationDelay: '1s',
      // width: '0%',
      // animationIterationCount: 20,
      fontFamily: 'Yeseva One',
      animation:
        'typing 1s forwards'
    },
    hrQeerio: {
      position: 'absolute',
      // display: 'none',
      background: 'rgb(255, 100, 83)',
      border: '1px solid rgb(255, 100, 83)',
      marginLeft: '0px',
      // animationDelay: '3s',
      width: isMd ? '250px' : '120px',
      animation:
        'hranimation 2s forwards'
    },
    lowerText: {
      position: 'absolute',
      color: 'white',
      marginTop: '15px',
      fontFamily: 'Yeseva One',
      fontSize: isMd ? '23px' : '13px',
      animation:
        'lowertext 6s forwards',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    lowerText2: {
      position: 'absolute',
      marginTop: '50px',
      color: 'white',
      fontFamily: 'Yeseva One',
      fontSize: '23px',
      animation:
        'lowertext2 7s forwards',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    iconAppliGuestlist: {
      border: '1px solid white',
      cursor: 'pointer',
      height: isMd ? '120px' : '60px',
      width: isMd ? '120px' : '60px',
      borderRadius: isMd ? '25px' : '10px',
      background: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)'
      },
      animation:
        'appliguestlist 6s forwards'
    },
    iconAppliProductionbook: {
      border: '1px solid white',
      cursor: 'pointer',
      height: isMd ? '120px' : '60px',
      width: isMd ? '120px' : '60px',
      borderRadius: isMd ? '25px' : '10px',
      background: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      animation:
        'appliproductionbook 7s forwards',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)'
      },
    },
    iconAppliInventory: {
      border: '1px solid white',
      height: isMd ? '120px' : '60px',
      width: isMd ? '120px' : '60px',
      cursor: 'pointer',
      borderRadius: isMd ? '25px' : '10px',
      background: 'black',
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
      animation:
        'appliinventory 8s forwards',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    appliText: {
      marginTop: '10px',
      color: 'white',
      fontFamily: 'Yeseva One',
      whiteSpace: 'nowrap',
      fontSize: isMd ? '18px' : '12px'
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Seo />
      <div className={classes.centerPage}>
        <div className={classes.topPage}>
          <div className={classes.typeWriterEffect}>
            Qeerio
          </div>
          <div>
            <hr className={classes.hrQeerio} />
            <div className={classes.lowerText}>
              Smart <span style={{ color: 'rgb(255, 100, 83)' }}>apps</span> to boost your event<span style={{ color: 'rgb(255, 100, 83)' }}>.</span>
            </div>
          </div>
        </div>
        <div style={{
          position: 'relative',
          top: '50%',
          transform: isMd ? 'translateY(-75%)' : 'translateY(-40%)',
          margin: 'auto',
          width: isMd ? '60%' : '100%',
          height: '50%',
          display: 'flex',
          flexDirection: isMd ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: 'space-around'
        }}>
          <a href='/guestlist' style={{ textDecoration: 'none' }}>
            <div className={classes.iconAppliGuestlist}>
              <Image
                src={require('../assets/images/main/guestlisticon.png').default}
                alt="Guestlist icon"
                lazy
              />
              <div className={classes.appliText}>
                GUEST LIST
              </div>
            </div>
          </a>
          <a href='/productionbook' style={{ textDecoration: 'none' }}>
            <div className={classes.iconAppliProductionbook}>
              <Image
                src={require('../assets/images/main/productionbookicon.png').default}
                alt="Productionbook icon"
                lazy
              />
              <div className={classes.appliText}>
                PRODUCTION BOOK
              </div>
            </div>
          </a>
          <a href='/inventory' style={{ textDecoration: 'none' }}>
            <div className={classes.iconAppliInventory}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // backgroundColor: 'rgb(50, 50, 50)',
                borderRadius: '15px'
              }}>
                <Image
                  src={require('../assets/images/main/inventoryicon.png').default}
                  alt="Inventory icon"
                  lazy
                />
                <div className={classes.appliText}>
                  INVENTORY
                </div>
              </div>
              {/* <div style={{
              bottom: '100%',
              right: '43%',
              width: '200%',
              // height: '30%',
              padding: '5px',
              transform: 'rotate(-30deg)',
              position: 'relative',
              background: 'rgb(25,25,25)',
              fontWeight: 800,
              border: '1px solid rgb(255, 100, 83)',
              // fontFamily: 'Yeseva One',
              fontSize: isMd ? '20px' : '10px',
              textAlign: 'center',
              color: 'white',
              animation:
                'comingsoon 9s forwards'
            }}>
              <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                COMING SOON
              </div>
            </div> */}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
